html{
  overflow: hidden;
}
body {

  margin: 0;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;

  font-family: 'Montserrat', sans-serif;
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

* {
  box-sizing: border-box;
}

.link-dim:hover {
  opacity: 0.8;
  cursor: pointer;
}

.dark-overlay {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: black;
    opacity: 0.3;
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }

  & > * {
    z-index: 2;
  }
}

.fa-shadow {
  text-shadow: 0 0 3px black;
}

.no-scrollbar {
  .ps__rail-x,
  .ps__rail-y {
    display: none !important;
  }
}

// light gallery overrides

.lg-outer {
  .lg-toolbar {
    top: 0;
    height: 50px;
  }

  #lg-counter {
    color: white;
  }

  .lg-sub-html {
    z-index: 2000;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    background-color: transparent;
  }

  .lg-close {
    opacity: 1 !important;
    color: white !important;
  }

  .lg-video {
    width: 100%;
    padding-bottom: 56.25% !important;
    position: inherit !important;
  }
}

@media(max-width: 869px){
  html{
    overflow-x: hidden;
  }
  body{
    overflow-y: scroll;
  }
}

html[data-useragent*='kioskip'] {
  .lg-on {
    .lg-outer {
      .lg-video {
        .lg-object {
          height: 95% !important;
        }
      }
    }
  }

  .lg-toolbar {
    top: initial;
    bottom: 0;
    padding-left: 10%;
    padding-right: 10%;

       #lg-counter {
      padding-left: 0;
    }

    .lg-close {
      margin-right: 25px;

      &:before {
        content: "\e070";
      }

      &:after {
        content: "Fechar" !important;
        font-family: 'Montserrat';
        font-size: 16px;
        vertical-align: top;
      }
    }
  }
}
